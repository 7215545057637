<template>
    <main-body>
        <template #content>

            <b-button @click="callApi()">call api</b-button>

            <!-- monitoring -->
            <p v-if=""><b>apiData:</b> {{apiData}}</p>
            <hr>
            <!-- end monitoring -->

            <!--        Testing2: {{value}}-->

            <!--        <div class="my-2">
                        <testing2-sub :value="value" @update:value="updateValue"></testing2-sub>
                    </div>-->

            <!-- test element experience -->
            <!--        <div>
                        <b-button @click="getResize()">getSize</b-button>
                    </div>-->

            <!--        <div class="element m-2">
                        &lt;!&ndash; header &ndash;&gt;
                        <div class="header"></div>
                        &lt;!&ndash; body &ndash;&gt;
                        <div class="body" v-resize @resize="bodyResize">
                            &lt;!&ndash; body left &ndash;&gt;
                            <div class="body-left">

                            </div>
                            &lt;!&ndash; body right &ndash;&gt;
                            <div ref="aaaBbb" class="body-right p-1 overflow-auto" style="max-height: 130px; max-width: 70%">
                                <div v-for="element in 20"
                                     class="m-1 d-inline-block"
                                     style="width: 40px; height: 20px; background: whitesmoke">
                                    {{element}}
                                </div>
                            </div>
                        </div>
                        &lt;!&ndash; footer &ndash;&gt;
                        <div class="footer">
                        </div>
                    </div>-->

            <!-- image -->
            <!--                <div class="m-2" style="width:200px; height: 150px">
                                <b-carousel
                                    id="carousel-fade"
                                    style="text-shadow: 0px 0px 2px #000"
                                    fade
                                    indicators
                                >
                    &lt;!&ndash;                img-width="1024"&ndash;&gt;
                    &lt;!&ndash;                img-height="480"&ndash;&gt;
                                    <b-carousel-slide
                                        caption="First Slide"
                                        :img-src="imagePath"
                                    ></b-carousel-slide>
                                    <b-carousel-slide
                                        caption="First Slide"
                                        img-src="https://picsum.photos/1024/480/?image=10"
                                    ></b-carousel-slide>
                                    <b-carousel-slide
                                        caption="Second Slide"
                                        img-src="https://picsum.photos/1024/480/?image=12"
                                    ></b-carousel-slide>
                                    <b-carousel-slide
                                        caption="Third Slide"
                                        img-src="https://picsum.photos/1024/480/?image=22"
                                    ></b-carousel-slide>
                                </b-carousel>
                            </div>-->

            <!-- resize flex div -->
            <!--        <div class="parent bg-info-light m-2">
                        <div class="child-left">
                            aaa aaaaaa1 aaaaaaa2 aaaaaa1 aaaaaaa2 aaaaaa1 aaaaaaa2 aaaaaa1 aaaaaaa2 aaaaaa1 aaaaaaa2 aaaaaa1
                            aaaaaaa2 aaaaaa1 aaaaaaa2 aaaaaa1 aaaaaaa2 end
                        </div>

                        <div class="child-right">
                            <div class="child-r-top">
                            bbb bbbbbbb1 bbbbbbbbb1 bbbbbbb1 bbbbbbbbb1 bbbbbbb1 bbbbbbbbb1 bbbbbbb1 bbbbbbbbb1 bbbbbbb1 bbbbbbbbb1
                            bbbbbbb1 bbbbbbbbb1 end
                            </div>

                            <div class="child-r-bottom">bottom</div>
                        </div>

                    </div>-->
        </template>
    </main-body>
</template>

<script>
import Testing2Sub from "@/components/developer/Testing2Sub";
import MainBody from "@/components/general/layout/MainBody";

export default {
    name: "Testing2",
    components: {MainBody, Testing2Sub},
    props: {},
    data() {
        return {
            value: 'Jožica kr Neki',
            imagePath: 'http://localhost:26816/images/experience/f86e9dfa89407afbf4ee0f1ed6eb2e3a.jpg',
            apiData: null,
        }
    },
    mounted() {
    },
    methods: {
        updateValue(data) {
            this.value = data;
        },
        // <-- test element experience -->
        getResize() {
            // console.log("aaa", this.$refs.aaaBbb);
        },
        bodyResize(e) {
            // console.log("resizeBody", e);
        },
        // <-- api -->
        callApi() {
            this.$root.mx_busy = true;
            this.routes = [];
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <--  -->
            // this.axios.get(this.uri.main, config)
            let uri = 'https://jsonplaceholder.typicode.com/posts';
            this.axios.get(uri)
                .then((response) => {
                    // console.log("responseData", response);
                    console.log("responseData", response.data[0].id, response.data[0].title);

                    this.$set(this, 'records', response.data);
                    this.records = response.data;

                    this.$root.mx_busy = false;
                })
                .catch((error) => {
                    console.log("ERROR:", error.response.data);
                    this.$root.mx_busy = false;
                });
        },
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>
.parent {
    background: #FFB871;
    width: 220px;
    height: 130px;
    display: flex;
}

.child-left {
    background: #fd7e14;
    width: 40%;
    height: inherit;
    overflow: auto;
}

.child-right {
    background: #17a2b8;
    width: 60%;
    height: inherit;
    display: flex;
    flex-direction: column;
}

.child-r-top {
    background: #42b983;
    flex: 1 1 auto;
    overflow: auto;
}

.child-r-bottom {
    background: #e83e8c;
    height: 30px;
}

.element {
    width: 320px;
    height: 190px;
    background: lightgray;
    display: flex;
    flex-direction: column;
}

.header {
    height: 30px;
    background: #20c997;
}

.body {
    background: #17a2b8;
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
}

.body-left {
    background: #fd7e14;
    flex: 1 0 auto;
    min-width: 30%;
    max-width: 30%;
}

.body-right {
    background: darkmagenta;
    flex: 1 0 auto;
}

.footer {
    height: 30px;
    background: #D1E4A0;
}
</style>
