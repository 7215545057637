<template>
    <div class="overflow-auto" v-resize @resize="layoutTemplateResize"
         :style="{height: getTemplateHeight.px}"
    >
        <!-- slot content -->
        <slot name="content">

        </slot>
    </div>
</template>

<script>
export default {
    name: "MainBody",
    components: {},
    props: {},
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
        layoutTemplateResize(e) {
            this.templateLayout = {
                width: e.detail.width,
                height: e.detail.height,
            };
        },
    },
    computed: {
        getTemplateHeight() {
            let height = this.__layoutCenterHeightCalculate.value;
            // <--  -->
            return {
                value: height,
                px: height + 'px'
            }
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
