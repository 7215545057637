<template>
    <div>
        testingSub: {{value}}

        <div class=" mt-2">
            <b-button @click="setValue()">aaa</b-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "Testing2Sub",
    components: {},
    props: {
        value: {
            type: String,
            default: 'Jožica'
        }
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
        setValue(){
            this.getSetValue = 'Marija Bizjakovim';
        }
    },
    computed: {
        getSetValue: {
            get: function () {
                return this.value;
            },
            set: function (newValue) {
                this.$emit('update:value', newValue);
            },
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
